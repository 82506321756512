.card-chat{
    min-width: 250px;
    height: 240px;
 
    padding: 5px 5px;
    background-color: white;
    margin: 0px 1px 0px 1px;
}
.img-chat{
    height: 120px;
    width: 150%;
}
.icon-chat{
    display: flex;
}