.chat-height{
    height: 450px;
    margin: 10px 10px;
    min-width: 370px;
    border-radius: 10px;
    /* background-color: rgb(3, 3, 3); */
    border: 2px solid #92B161;
    position: fixed;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 99999;
}
.heading{
    font-size: 22px;
    padding-left: 10px;
    font-weight: 700;
    background-color: #92B161;
    border-radius: 10px 10px 0px 0px;
    /* height: 40px; */
    color: white;
}
.text{
    overflow: scroll;
}
.c-width{
    width: 100%;
    background-color: white;
    height: 2px;
}
.t1{
    height: 53px;
    margin: 5px 5px;
    border-radius: 10px;
    padding: 10px 7px;
    width: 90%;
    background-color: white;
}
.t2{
    height: 80px;
    margin: 5px 5px;
    border-radius: 10px;
    padding: 10px 7px;
    width: 90%;
    background-color: white;
}

.t1 p{
    font-size: 14px;
}
.t2 p{
    font-size: 14px;
}
.t3 p{
    font-size: 14px;
}
.t3{
    height: 130px;
    margin: 5px 5px;
    border-radius: 10px;
    padding: 10px 7px;
    width: 90%;
    background-color: white;
}

.text{
    height:350px;
    background-color: #e8edf1;
}
#input-text{
    width: 77%;
    position: relative;
    top: 1px;
    /* border: 2px solid black; */
    height: 55px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    /* border-radius: 10px; */
    padding-left: 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    /* border-radius: 0px; */
    /* background-color: black;    */
    position: relative;
    top:5px;
    left: 2px;
}
.number{
    height: 50px;
    width: 220px;
    position: relative;
    left: 150px;
    border-radius: 10px;
    color: black;
    /* background-color:rgb(203, 203, 203); */
}
.number-input{
    height: 40px;
    margin-top: 17px;
    width: 100%;
    padding-left: 10px;
    color: black;
    background-color:white;
}
.send{
    height: 53px;
    width: 75px;
    color: aliceblue;
    border-radius: 10px;
    background-color: #92B161;
    position: relative;
    top:3px;
    left: 8px;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: white;
    color: black;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-left: 20px;
    margin-top: 5px;
  }
  
  .button:hover {
    background-color: black;
    color: white;
  }
  .toggle-button {
    position: absolute;
    top: 5px;
    right: 20px;
    background-color: #92B161;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  /* Style for the open button */
  .open-button {
    position: fixed;
    bottom: 50px;
    right: 20px;
    background-color: #92B161;
    color: white;
    border: none;
    cursor: pointer;
    z-index: 99999999;
    border-radius: 50%;
    height: 80px;
    width: 80px;
  }
  
  /* Add styles for toggling visibility */
  .visible {
    display: block;
  }
  
  .hidden {
    display: none;
  }

  