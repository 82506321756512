@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@300&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body {
	font-family: 'Inter', sans-serif !important;
	font-family: 'Poppins', sans-serif !important;
}

@media screen and (max-width: 768px) {
	.rec.rec-arrow {
		display: none;
	}
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.react-tel-input .form-control {
	@apply !w-full py-6 !text-base;
  }
  
  .opt-container {
	@apply flex justify-between gap-2;
  }
  .opt-container input {
	@apply !mr-0 flex-1 py-5 outline-none;
  }

  ::-webkit-scrollbar{
	display: none;
  }