
  #brands{
    position: relative;
    right: 20%;
  }
  #sign {
    grid-column: 13/15;
    position: relative;
    left: 25%;
  
    width: 60%;
  }
  #sign button {
    font-family: "Montserrat", sans-serif;
  
    font-weight: 700;
  
    color: white;
  
    background-color: #92B161;
    width: 100%;
    height: 40px;
    border: 0px;
    border-radius: 5px;
    font-size: clamp(12px, 1vw, 14x);
  }
  #products {
    position: relative;
    /* left: 85%; */
    right: 24%;
  }
  #abt{
    position: relative;
    /* left: 85%; */
    right: 18%;
  }
  
  #log {
    grid-column: 12/13;
    position: relative;
    left: 35%;
  }
  #log a {
    color: #92B161;
  }
  #menu {
    display: none;
  }
  #search {
  
    grid-column: 8/10;
    position: relative;
    left: 15%;
    top: 0%;
  }
  #search button{
    background-color: transparent;
    border: none;
  }
  #simg{
    z-index: 99;
    position: relative;
    top: 23px;
    left: 18px;
    height: 20px;
    width: 20px;
  }
  #search-mobile{
    display: none;
  }
  input{
    border-radius: 10px;
    font-family: "montserrat" , sans-serif;
    border: none;
    height: 40px;
    width: 180%;
    position: relative;
    bottom: 20%;
    z-index: 0;
    padding-left: 50px;
  }
  .phone{
    margin-bottom: 20px;
   
    background-color: rgb(241, 241, 241);



  }

  .opt-container input{
    border: 1px solid black !important;
  }
  .material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 800, "GRAD" 0, "opsz" 48;
  }
  
  .material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  }
  
  .material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  }
  #profile{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 15/ 16;
    position: relative;
    right: 20%;
    top: 1%;
  
  }
  #profile a img{
  
    width: 24px;
    height: 24px;
  
  }
  #bag {
    grid-column: 17 / 18;
    
    position: relative;
    right: 175%;
  }
  #cart {
    grid-column: 16/ 17;
    position: relative;
    right: 50%;
  }
  #cart a img{
  
    width: 24px;
    height: 24px;
  
  }
  
  #login{
    font-family: "Montserrat", sans-serif;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    /* vertical-align: middle; */
    margin: 05% 20%; 
  }

  
  h2{
    font-weight: 700;
    font-size: clamp(30px, 1vw, 40px);
    margin-bottom: clamp(3px,1vw,20px);
  }
  
  h2 span{
    color: #FBB831;
  }
  
  #content{
    width:75%;
    text-align: center;
    margin-bottom: clamp(5px,1vw,10px);
    line-height: clamp(25px,1vw,35px);
  }
  
  
  label{
    font-weight: 700;
    font-size: clamp(12px,1vw,15px);
  }
  
  #name{
    
    height: 35px;
    width: 330px;
    margin-bottom: clamp(10px,1vw,20px);
    border: 0;
    background-color: rgb(241, 241, 241);
    padding-left: 5px;
    font-size: 20px;
    margin-top: 7px;
  }

  
 
  #pwd{
    height: 35px;
    width: 330px;
    margin-bottom: clamp(10px,1vw,20px);
    border: 0;
    background-color: rgb(241, 241, 241);
    padding-left: 5px;
    font-size: 20px;
    margin-top: 7px;
  }
  
  #Email{
    height: 35px;
    width: 330px;
    margin-bottom: clamp(10px,1vw,20px);
    border: 0;
    background-color: rgb(241, 241, 241);
    padding-left: 5px;
    font-size: 20px;
    margin-top: 7px;

  }
  #re-pwd{
    height: 35px;
    width: 330px;
    margin-bottom: clamp(10px,1vw,20px);
    border: 0;
    background-color: rgb(241, 241, 241);
    padding-left: 5px;
    font-size: 20px;
    margin-top: 7px;

  }
  #forgot-password{
    
    color: #92B161;
    font-weight: 550; 
    font-size: clamp(13px, 1vw, 15px);
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 7px;
    
  }
  
  #final-login{
    font-size: clamp(10px, 1vw, 40px);
    width: 100%;
    padding: 2.7%;
    background: #92B161;
    color: white;
    margin-bottom:clamp(10px,1vw,20px) ;
    border: 0;
  }
  
  #no-account{
    font-weight: 550;
    font-size: clamp(14px, 1vw, 15px);
    display: flex;
    justify-content: center;
  }
  
  #no-account a{
    color: #92B161;
    text-align: center;
    position: relative;
    left: 2%;
  }
  
  
  @media (max-width:768px){
    #login{
      margin: 10% 8%;
    }
  
    h2{
      font-size: clamp(28px, 1vw, 35px);
      text-align: center;

    }
  
    #content{
      width: 100%;
    }
   
    label{
      font-size: clamp(12px,1vw,14px);
    }
  }
  
  @media (max-width:525px){
    #login{
      margin: 10% 0% ;
    }
    h2{
      font-size: clamp(27px, 1vw, 35px);
      text-align: center;

    }
    #content{
      font-size:  clamp(15px, 1vw, 20px);
      line-height: clamp(20px,1vw,20px);
      width: 94%;
      margin-bottom: clamp(40px,1vw,50px);
    }
  }
  
  
  @media(max-width:410px){
    #login{
      margin: 10% 0%;
    }
  
    h2{
      text-align: center;
      font-size: clamp(20px, 1vw, 25px);
      margin-bottom: clamp(10px,1vw,25px);
    }
    #content{
      font-size:  clamp(13px, 1vw, 20px);
      line-height: clamp(16px,1vw,20px);
      margin-bottom: clamp(60px,1vw,70px);
      width: 90%;
    }
  
  }
