.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 145px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestion-active {
  background-color: #fff;
  color: #000;
  cursor: pointer;
  font-weight: 700;
}

.suggestion-active :hover {
  background-color: rgb(13, 13, 224);
  color: white;
  cursor: pointer;
  font-weight: 700;
}